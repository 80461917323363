<template>
  <div class="hotel-information">
    <h3 class="sub-title">Oral Presentation</h3>
    <div class="search-input">
      <a-input-search placeholder="Keyword / Name / Abstract No." size="large" allow-clear enter-button @search="onSearch" />
    </div>
    <div class="type-bar">
      <div class="type-item" :class="{'active':item===typeStatus}" v-for="(item,index) in typeList" :key="index" @click="typeChange(item)">
        {{ item }}
        <span class="corner">{{ getNumber(item) }}</span>
      </div>
    </div>
    <div class="ul">
      <div class="li" v-for="(item,index) in showList" :key="index">
<!--        <div class="top">
          <span class="badge badge-secondary">O-1707</span>
          <span class="badge badge-info">PAC-001</span>
          <span class="right">June 1 (Thu), Convention Hall Lobby, 1F</span>
        </div>-->
        <div class="mid" v-html="resolveMarkText(item.title)"></div>
        <div class="bottom">
          <span class="lt">
            <span v-html="resolveMarkText(item.author)"></span>
            <span v-if="item.country">(<span v-html="resolveMarkText(item.country)"></span>)</span> 
          </span>
          <span class="rt" @click="handleClick(item)" v-if="item.documentInfo && item.documentInfo.length > 0">
            <span class="iconfont icon-jia"></span>
            View
          </span>
        </div>
      </div>
    </div>
    <a-modal
      v-model="visible"
      @close="handelClose"
      :centered="true"
      width="1106"
    >
      <template slot="footer">
        <a-button key="cancel" @click="handelClose">
          cancel
        </a-button>
      </template>
      <PdfPreview :documentUrl="documentUrl"></PdfPreview>
    </a-modal>
  </div>
</template>

<script>
const TOPIC_TYPE = {
  Cardiac: 0,
  Thoracic: 1,
  Pediatric: 2,
  Vascular: 3
}

import { getAbstractList } from '@/api/abstract'
import PdfPreview from "@/components/PdfPreview";

export default {
  name:'OralPresentation',
  components:{
    PdfPreview
  },
  data(){
    return {
      typeStatus: 'Adult Cardiac',
      typeList: ['Adult Cardiac', 'Heart Failure', 'Pediatric', 'Thoracic'],
      dataList: [],
      abstractList: [],
      cardiacList: [],
      thoracicList: [],
      pediatricList: [],
      vascularList: [],
      searchVal: '',
      visible: false,
      documentUrl: ''
    }
  },
  created(){
    this.initAbstractList()
  },
  methods:{
    async initAbstractList(){
      const res = await getAbstractList({ type: 0 })
      if (res.code === 0) {
        this.dataList = res.data
        this.abstractList = res.data
      }
    },
    onSearch(val){
      if (!val) {
        this.abstractList = this.dataList;
      } else {
        this.abstractList = this.dataList.filter((item) => {
          return item.title.toLowerCase().includes(val.toLowerCase()) || item.author.toLowerCase().includes(val.toLowerCase()) || (item.country && item.country.toLowerCase().includes(val.toLowerCase()))
        });
      }
      this.searchVal = val;
    },
    typeChange(type){
      this.typeStatus = type
    },
    getNumber(type){
      switch (type) {
        case 'Adult Cardiac':
          return this.cardiacList.length
        case 'Heart Failure':
          return this.vascularList.length
        case 'Pediatric':
          return this.pediatricList.length
        case 'Thoracic':
          return this.thoracicList.length
      }
    },
    resolveMarkText(markText) {
      if (!this.searchVal) return markText;
      const markArr = this.getMarkIndexArr(markText, this.searchVal);
      return this.replaceMark(markText, this.searchVal, markArr);
    },
    getMarkIndexArr(str, mark, position) {
      if (!position) position = 0;
      if (!str || !mark) return [];
      const index = str.toLowerCase().indexOf(mark.toLowerCase(), position)
      if (index < 0) return [];
      const positionArr = [index];
      if ((index + mark.length) < str.length) {
        positionArr.push(...this.getMarkIndexArr(str, mark, index + mark.length))
      }
      return positionArr
    },
    replaceMark(str, mark, markArr = []) {
      if (!markArr || markArr.length <= 0) return str;
      let position = 0;
      let splitArr = [];
      markArr.forEach((item, index) => {
        splitArr.push(str.slice(position, item));
        position += (item - position);
        splitArr.push(`<span class="highlight">${str.slice(position, position + mark.length)}</span>`)
        position += mark.length
        if (markArr.length - 1 === index) {
          splitArr.push(str.slice(position))
        }
      });
      return splitArr.join('');
    },
    handelClose() {
      this.visible = false;
    },
    handleClick(item){
      this.documentUrl = item.documentInfo[0].fileUrl
      this.visible = true;
    }
  },
  watch:{
    abstractList:{
      deep:true,
      immediate:true,
      handler(val){
        this.cardiacList = []
        this.thoracicList = []
        this.pediatricList = []
        this.vascularList = []
        val.forEach(item=>{
          switch (item.topic) {
            case TOPIC_TYPE.Cardiac:
              this.cardiacList.push(item)
              break;
            case TOPIC_TYPE.Thoracic:
              this.thoracicList.push(item)
              break;
            case TOPIC_TYPE.Pediatric:
              this.pediatricList.push(item)
              break;
            case TOPIC_TYPE.Vascular:
              this.vascularList.push(item)
              break;
            default:
              this.cardiacList.push(item)
          }
        })
      }
    },
  },
  computed:{
    showList(){
      switch (this.typeStatus) {
        case 'Adult Cardiac':
          return this.cardiacList
        case 'Heart Failure':
          return this.vascularList
        case 'Pediatric':
          return this.pediatricList
        case 'Thoracic':
          return this.thoracicList
        default :
          return []
      }
    }
  }
}
</script>

<style lang="less" scoped>
.hotel-information {
  .sub-title {
    font-weight: 900;
    font-size: 27px;
    line-height: normal;
    margin: 30px 0px 20px 0px;
    padding-bottom: 10px;
    border-bottom: solid 1px #ddd;
  }
  .search-input {
    margin-bottom: 20px;
    /deep/ .ant-input:hover {
      border-color: #19317e;
    }
    /deep/ .ant-input-search-button {
      background-color: #19317e;
      border-color: #19317e;
      &:hover {
        background-color: rgba(25,49,126, 0.8);
        border-color: rgba(25,49,126, 0.8);
      }
    }
    /deep/ .ant-input:focus {
      border-color: #19317e;
      box-shadow: 0 0 0 2px rgba(25,49,126, 0.3);
    }
  }
  .type-bar {
    display: flex;
    height: 64px;
    line-height: 64px;
    border: 1px solid #dee2e6;
    background-color: #f8f9fa;
    border-radius: 32px;
    margin: 15px 0;
    .type-item {
      font-size: 21px;
      flex: 1 1 auto;
      text-align: center;
      color: #1175e7;
      border-radius: 32px;
      transition: all .4s;
      cursor: pointer;
      .corner {
        position: relative;
        top: -15px;
        font-size: 12px;
        line-height: 20px;
        height: 20px;
        background-color: #f8b301;
        color: #000;
        padding: 0 10px;
        border-radius: 10px;
      }
    }
    .active {
      color: #fff;
      background:linear-gradient(73deg, rgba(63,136,163,1) 30%, rgba(88,46,104,1) 100%);
    }
    @media (max-width: 768px) {
      height: 54px;
      line-height: 54px;
      .type-item {
        font-size: 18px;
        border-radius: 27px;
      }
    }
    @media (max-width: 576px) {
      height: 42px;
      line-height: 42px;
      border-radius: 4px;
      width: calc( 100vw - 30px );
      overflow-x: scroll;
      overflow-y: hidden;
      .type-item {
        text-align: center;
        color: #1175e7;
        border-radius: 4px;
        flex: 0 1 auto;
        white-space: nowrap;
        padding: 0 15px;
      .corner {
        position: relative;
        top: -10px;
        font-size: 12px;
        line-height: 20px;
        height: 20px;
        background-color: #f8b301;
        color: #000;
        padding: 0 10px;
        border-radius: 10px;
      }
      }
      .active {
        color: #fff;
      }
    }
  }
  .ul {
    .li {
      border: 1px solid #ddd;
      padding: 12px 20px;
      &:nth-child(2n+1) {
        background-color: #f8f9fa;
      }
      & + .li {
        border-top: none;
      }
      .top {
        margin-bottom: 8px;
        .badge {
          display: inline-block;
          padding: 4px 5px;
          font-size: 12px;
          line-height: 1;
          border-radius: 4px;
          color: #fff;
          &.badge-secondary {
            background-color: #6c757d;
            margin-right: 5px;
          }
          &.badge-info {
            background-color: #17a2b8;
          }
        }
        .right {
          color: #146992;
          font-size: 14px;
          float: right;
        }
      }
      .mid {
        padding: 4px 0;
        font-size: 16px;
        font-family: "Akrobat Regular";
      }
      .bottom {
        font-size: 16px;
        .lt {
          color: #146992;
          margin: 4px 0 8px;
          display: inline-block;
        }
        .rt {
          float: right;
          color: #17a2b8;
          border-radius: 4px;
          border: 1px solid #17a2b8;
          padding: 4px 8px;
          font-size: 14px;
          transition: all .3s;
          cursor: pointer;
          &:hover {
            color: #fff;
            background-color: #17a2b8;
          }
        }
      }
      /deep/ .highlight {
        background-color: #FFFF88;
      }
    }
  }
}
@media (min-width: 420px){
  /deep/ .ant-modal {
    min-width: 400px;
    max-width: calc( 100vw - 16px);
  }
}
@media (min-width: 767px){
  /deep/ .ant-modal {
    min-width: 760px;
    max-width: calc( 100vw - 16px);
  }
}
</style>
