import request from '@/utils/request'

const abstractApi = {
  getAbstractListUrl: "/api/sysMeeting/abstract",
  getMyAbstractUrl: "/api/sysMeeting/myAbstract",
  getAbstractInfoByIdUrl: "/api/sysMeeting/abstractInfo",
  addAbstractUrl: "/api/sysMeeting/addAbstract",
  modifyAbstractUrl: "/api/sysMeeting/modifyAbstract",
  delAbstractUrl:  "/api/sysMeeting/delAbstract"
}

/**
 * 获取摘要列表
 * keywords
 * topic
 * type
 * @returns {*}
 */
export function getAbstractList(params) {
  return request({
    url: abstractApi.getAbstractListUrl,
    method: 'get',
    params
  })
}

/**
 * 添加摘要
 * dto
 * thirdSubId
 * @returns {*}
 */
export function addAbstract(params) {
  return request({
    url: abstractApi.addAbstractUrl,
    method: 'post',
    data: params
  })
}

/**
 * 编辑摘要
 * dto
 * thirdSubId
 * @returns {*}
 */
export function modifyAbstract(params) {
  return request({
    url: abstractApi.modifyAbstractUrl,
    method: 'post',
    data: params
  })
}

/**
 * 删除摘要
 * id
 * thirdSubId
 * @returns {*}
 */
export function delAbstract(params) {
  return request({
    url: abstractApi.delAbstractUrl,
    method: 'get',
    params
  })
}

/**
 * 我的摘要列表
 * thirdSubId
 * @returns {*}
 */
export function getMyAbstract(params) {
  return request({
    url: abstractApi.getMyAbstractUrl,
    method: 'get',
    params
  })
}

/**
 * 我的摘要列表
 * params
 * @returns {*}
 */
export function getAbstractInfoById(params) {
  return request({
    url: abstractApi.getAbstractInfoByIdUrl,
    method: 'get',
    params
  })
}


